<template>
  <!-- 预览志愿详情 -->
  <div class="new_preview_page">
    <div class="page_head" v-if="userInfo && userInfo.is_new">
      <div class="new_head">
        <div>
          <span>{{ userInfo.point }}分</span>
          <span>{{ userInfo.position }} 位</span>
          <span>{{ userInfo.subject_str }}</span>
        </div>
      </div>
      <span>共{{ num }}个</span>
    </div>
    <div class="fill_school_list">
      <div class="cover_box">
        <ul class="fill_list">
          <li v-for="(item, index) in fillDataList" :key="index" class="item">
            <div class="index_num">
              <span class="key">{{ index + 1 }}</span>
            </div>
            <div class="major_info">
              <div class="major_name">
                <span>{{ item.majorInfo.name }}</span>  
                <span class="major_code">代码 {{ item.majorInfo.code }}</span>  
              </div>
              <div class="gray_label">
                <span>{{ item.majorInfo.require_subjects }}</span>
                <span
                  >{{ currentYear }}计划{{
                    item.majorInfo.data[0].new_counts
                  }}</span
                >
                <span>学制{{ item.majorInfo.years }}</span>
                <span>学费{{ item.majorInfo.money }}</span>
              </div>
              <div class="school_name new_school_name">
                <span>{{ item.schoolInfo.name }}</span>
                <span class="gray_label code_label" v-if="item.schoolInfo.code"
                  >代码 {{ item.schoolInfo.code }}</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <draggable
        class="list"
        v-model="fillDataList"
        :options="{
          group: 'layout',
          draggable: '.item',
          sort: true, // 内部排序列表
          animation: 50
        }"
        @update="datadragEnd"
      >
        <transition-group tag="ul" class="fill_list">
          <li v-for="(item, index) in fillDataList" :key="index" class="item">
            <div class="index_num">
              <span class="key">{{ index + 1 }}</span>
            </div>
            <div class="major_info">
              <div class="major_name">
                <span>{{ item.majorInfo.name }}</span>  
                <span class="major_code">代码 {{ item.majorInfo.code }}</span>  
              </div>
              <div class="gray_label">
                <span>{{ item.majorInfo.require_subjects }}</span>
                <span
                  >{{ currentYear }}计划{{
                    item.majorInfo.data[0].new_counts
                  }}</span
                >
                <span>学制{{ item.majorInfo.years }}</span>
                <span>学费{{ item.majorInfo.money }}</span>
              </div>
              <div class="school_name new_school_name">
                <span>{{ item.schoolInfo.name }}</span>
                <span class="gray_label code_label" v-if="item.schoolInfo.code"
                  >代码 {{ item.schoolInfo.code }}</span
                >
              </div>
            </div>

            <div class="major_order_wrap">
              <i class="el-icon-s-fold"></i>
            </div>
          </li>
        </transition-group>
      </draggable>
    </div>
    <el-button
      type="primary"
      v-if="fillDataList.length > 0"
      class="create_new_zhiyuan"
      @click="createMyTable"
      >保存<br />志愿</el-button
    >
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";

export default {
  components: { draggable },
  data() {
    return {
      fillDataList: [
        // {
        //   id: 1,
        //   schoolName: "",
        //   majorNum: 0
        // }
      ],
      tableData: [],

      num: 0
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear
    })
  },
  mounted() {
    this.num = localStorage.getItem("selectTotalNum");
    this.tableData = JSON.parse(localStorage.getItem("allSchoolData"))|| [];

    this.dealData(this.tableData);
  },
  methods: {
    dealData(data) {
      data.forEach(i => {
        if (i.professionals && i.professionals.length > 0) {
          i.professionals.forEach(p => {
            if (!!p.isSelect) {
              this.fillDataList.push({
                majorInfo: p,
                schoolInfo: i,
                schoolId: i.id
              });
            }
          });
        }
      });
      console.log("this.fillDataList--", this.fillDataList);
    },
    datadragEnd(evt) {
      //   console.log("拖动前的索引 :" + evt.oldIndex);
      //   console.log("拖动后的索引 :" + evt.newIndex);
      console.log(this.fillDataList);
    },
    async createMyTable() {
      if (this.userInfo && this.userInfo.id) {
        if (this.fillDataList.length == 0) {
          this.$toast("请填报专业");
          return;
        }
        // console.log('结果----',this.fillDataList);
        const apiUrl = "/api/volunteer/";
        const postData = {
          tag: "",
          data: this.fillDataList
        };
        console.log(postData);
        this.$toast("志愿表生成中...");
        try {
          const res = await this.$axios.post(apiUrl, postData);
          if (res.data.errno === 0) {
            this.$toast("志愿表已生成");
            setTimeout(() => {
              this.$router.push({ path: "/myCenter" });
            }, 1000);
          }
        } catch (error) {
          console.log(error);
        }
      }else {
        this.$router.push({ name: "login" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.new_preview_page {
  text-align: left;
  min-height: 100vh;
  box-sizing: border-box;
  background: #efefef;
  .page_head {
    color: #666;
    display: flex;
    align-items: center;
    height: 0.88rem;
    padding: 0 0.4rem;
    background: #fff;
    justify-content: space-between;
    span {
      margin-right: 10px;
    }
    a {
      border: 1px solid #a50000;
      border-radius: 3px;
      padding: 4px;
      margin-left: 10px;
    }
  }
  .new_head {
    display: flex;
    align-items: center;
  }
  .fill_school_list {
    margin-top: 0.2rem;
    padding-bottom: 2.4rem;
    background: #efefef;
    .cover_box {
      position: absolute;
      left: 0;
      top: 0;
      width: 90%;
      z-index: 10;
      margin-top: 1.08rem;
    }
    .el-icon-s-fold {
      margin-left: 10px;
      font-size: 20px;
      color: #b9b9b9;
    }
    .major_order_wrap {
      color: #888;
      margin-top: 0.1rem;
    }
  }
  .el-button.create_new_zhiyuan {
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
    background: #910000;
    border-color: #910000;
    margin-top: 30px;
    border-radius: 50%;
    position: fixed;
    bottom: 1rem;
    right: 0.5rem;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  ul.fill_list {
    li {
      border-bottom: 1px solid #ececec;
      padding: 0.3rem;
      display: flex;
      justify-content: space-between;
      background: #fff;
      margin-bottom: 0.12rem;
      &:last-child {
        border: none;
      }
      .key {
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
        background: #910000;
        color: #fff;
      }
      .index_num {
        flex-basis: 8%;
      }
      .major_info {
        flex-basis: 90%;
        .new_school_name{
          span{
            margin-right: 0.1rem;
          }
        }
        .major_name {
          font-size: 0.28rem;
          font-weight: 600;
          overflow: hidden;
          margin-bottom: 0.1rem;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: flex;
          justify-content: space-between;
          .major_code{
            color: #999;
            font-size: 0.2rem;
            font-weight: normal;
          }
        }
        .gray_label {
          color: #999;
          font-size: 0.2rem;
          margin: 0.12rem 0 0.2rem 0;
          span {
            margin: 0 0.1rem;
          }
        }
        .school_name {
          color: #525252;
          padding-top: 0.2rem;
          margin-top: 0.2rem;
          font-size: 0.24rem;
          border-top: 1px dashed #dadada;
        }
      }
    }
  }
}
</style>
